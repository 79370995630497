import Vue from "vue";
import Vuex from "vuex";
//BOC
//arrange in alphabetic order
import auth from "./modules/auth";
import snackbar from "./modules/snackbar";
import hrdc from "@/services/hrdc/store/hrdc";
import hrdc_einvoicing from "@/services/hrdc_einvoicing/store/hrdc";
import hrdc_tax_summit from "@/services/hrdc_tax_summit/store/hrdc";
import hrdc_tpdiy from "@/services/hrdc_tpdiy/store/hrdc";
import service from "./modules/service";
import developer from "@/services/developer/store/developer";
import rolePermission from "./modules/rolePermission";
import parentSelectedModelValue from "./modules/parentSelectedModelValue";
//EOC

Vue.use(Vuex);
const debug = process.env.VUE_APP_ENV !== "production";

//BOC:[persist]
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
const vuexCookie = new VuexPersistence({
  key: "cookieStore",
  restoreState: (key) => Cookies.get(key),
  saveState: (key, state) =>
    Cookies.set(key, state, {
      expires: 30, // expire in 30 days
    }),
  modules: [
    //
  ],
});
const vuexLocal = new VuexPersistence({
  key: "localStore",
  storage: window.localStorage,
  modules: [
    //arrange in alphabetic order
    "auth",
    "developer",
    "hrdc",
    "hrdc_einvoicing",
    "hrdc_tax_summit",
    "hrdc_tpdiy",
    "snackbar",
    "service",
    "rolePermission",
    "parentSelectedModelValue",
  ],
});
//EOC

export default new Vuex.Store({
  modules: {
    //arrange in alphabetic order
    auth,
    developer,
    hrdc,
    hrdc_einvoicing,
    hrdc_tax_summit,
    hrdc_tpdiy,
    service,
    snackbar,
    rolePermission,
    parentSelectedModelValue,
  },
  strict: debug,
  plugins: [vuexCookie.plugin, vuexLocal.plugin],
});
