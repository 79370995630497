<template>
  <v-app>
    <v-app-bar
      app
      color="#063058"
      dark
      dense
      outlined
      elevation="0"
      elevate-on-scroll
      height="60"
    >
      <v-btn
        text
        class="px-1 border-0"
        @click="redirectRoute('PageConsoleDashboard')"
        exact
        exact-active-class="primary"
        >{{ $app.name }}</v-btn
      >
      <v-chip
        class="ml-1"
        x-small
        color="blue darken-4"
        style="font-size: 8px"
        >{{ $version }}</v-chip
      >
      <v-progress-linear
        v-if="api.isLoading && services.length < 1"
        indeterminate
        color="white"
        style="width: 30px"
        rounded
        class="mx-2"
      ></v-progress-linear>
      <div v-else class="d-flex align-center">
        <div class="px-1">/</div>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="px-1"
              @click="redirectRoute('PageServiceDashboard')"
            >
              {{ currentService ? currentService.name : "Services" }} ▼
            </v-btn>
          </template>
          <v-list dense nav>
            <v-list-item
              v-for="(item, index) in services"
              :key="index"
              @click="redirectRoute(item.route)"
              :disabled="!item.isEnabled || !item.isAllowed"
              :class="{
                'd-none': !item.isEnabled || !item.isAllowed,
                'v-list-item--active': item.key == $route.params.serviceKey,
              }"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!--BOC:[module]-->
      <div v-if="currentService && menu" class="d-flex align-center">
        <div class="px-1">/</div>
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              dark
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="px-1"
            >
              Modules ▼
            </v-btn>
          </template>
          <v-list dense nav :min-width="250">
            <template v-for="(item, index) in menu">
              <div :key="index">
                <template v-if="item.type == 'divider'">
                  <v-divider class="my-1" color="grey"></v-divider>
                </template>
                <v-list-item
                  v-else
                  @click="redirectRoute(item.route)"
                  :class="{
                    'd-none': !item.isAllowed,
                    'v-list-item--active':
                      item.route.params.modelKey == $route.params.modelKey,
                  }"
                  :disabled="
                    item.isAllowed !== undefined ? !item.isAllowed : false
                  "
                >
                  <v-list-item-icon v-if="item.icon" class="mr-2">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle v-if="!item.route">{{
                    item.name
                  }}</v-list-item-subtitle>
                  <v-list-item-title v-else>
                    {{ item.name }}
                    &nbsp;
                    <v-badge
                      v-if="item.count && item.count !== undefined"
                      :content="item.count"
                      color="green"
                    ></v-badge>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-menu>
      </div>
      <v-spacer />
      <AppHeaderProfile @toggleLogoutDialogChild="toggleLogoutDialog" />
    </v-app-bar>
    <v-main class="grey lighten-3">
      <router-view :key="$route.fullPath" />
      <Survey
        :surveyModel="surveyModel"
        :surveyQuestions="surveyQuestions"
        :surveyDetails="surveyDetails"
        :surveyAnswer="surveyAnswer"
        :errorField="errorField"
        @toggleSurveyDialogChildren="toggleSurveyDialog"
        @submitFormChildren="submitForm"
      ></Survey>
    </v-main>

    <TheModalConfirmation
      ref="confirmPopup"
      @agree="agreeCallback"
      @disagree="disagreeCallback"
    ></TheModalConfirmation>
  </v-app>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import configMenu from "@/config/menu";
import hrdcFunction from "@/objects/hrdcFunction";
import Survey from "@/components/views/v1/Survey";
import AppHeaderProfile from "@/components/layouts/AppHeaderProfile";
import TheModalConfirmation from "@/components/common/TheModalConfirmation.vue";

export default {
  mixins: [hrdcFunction],
  components: {
    Survey,
    AppHeaderProfile,
    TheModalConfirmation,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      debugMode: (state) => state.debugMode.data,
      services: (state) => state.service.data.services,
      currentService: (state) => state.service.data.currentService,
    }),
  },
  data: () => ({
    api: new Api(),
    apiGetUser: new Api(),
    apiGetSurveyQuestion: new Api(),
    apiSubmit: new Api(),
    menu: null,
    serviceKey: null,
    confirmationTitle: "Logout",
    confirmationMessage: "Are you sure to logout?",
    surveyModel: false,
    surveyQuestions: [],
    surveyDetails: {},
    surveyAnswer: {},
    errorField: {},
  }),
  watch: {
    "$route.params.serviceKey": {
      handler: function (newServiceKey, oldServiceKey) {
        if (newServiceKey != oldServiceKey) {
          this.$store.dispatch(
            "selectCurrentServiceByServiceKey",
            newServiceKey
          );
          if (newServiceKey) this.updateMenu(newServiceKey);
          this.serviceKey = newServiceKey;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const apiDetails = {
      api: {
        url: `${this.$service.crm}/v1/en/console/dashboard`,
        method: "post",
      },
      apiGetUser: {
        url: `${this.$service.sso}/v1/en/auth/me`,
      },
      apiGetSurveyQuestion: {
        url: `${this.$service.hrdc}/v1/en/console/survey/get-survey-by-uuid`,
      },
      apiSubmit: {
        url: `${this.$service.hrdc}/v1/en/console/survey`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetUser.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          this.$store.commit("updateAuth", data);
          this.api.fetch();
        }

        if (!status) {
          this.$router.push({ name: "PageConsoleLogout" });
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetUser.fetch();

    var internalRoleSlugs = this.$_.map(this.auth.roles, "slug");
    this.api.setParams({
      internalRoleSlugs: JSON.stringify(internalRoleSlugs),
    });
    this.api.setCallbackCompleted((response) => {
      var services = response;
      this.$store.dispatch("updateServices", services);
    });

    const survey = process.env.VUE_APP_SURVEY;
    if (survey) {
      this.apiGetSurveyQuestion.setQuery(
        `surveyUuid=${survey}&userUuid=${this.auth.uuid}`
      );
      this.apiGetSurveyQuestion.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status && data.SurveyResult.length == 0) {
            this.surveyModel = status;

            if (status) {
              this.surveyDetails = {
                uuid: data.uuid,
                title: data.title,
              };
              this.surveyQuestions = data.SurveyQuestion;

              for (const question of data.SurveyQuestion) {
                const fieldType = this.assignAnswerFieldType(
                  question.answerType
                );
                this.$set(this.surveyAnswer, question.uuid, fieldType);
                this.$set(this.errorField, question.uuid, null);
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });

      this.apiGetSurveyQuestion.fetch();
    }
  },
  methods: {
    updateMenu(serviceKey) {
      const targetMenu = configMenu[serviceKey];
      if (!targetMenu) {
        this.menu = null;
        return;
      }
      this.menu = this.$_.clone(targetMenu);
      this.menu.push({
        type: "divider",
      });
      this.menu.push({
        name: "Dashboard",
        route: {
          name: "PageServiceDashboard",
          params: { serviceKey: serviceKey },
        },
      });

      const data = {
        roles: this.auth.roles,
        menu: this.menu,
      };

      this.configMenu(data);
    },
    redirectRoute(routeName) {
      switch (routeName) {
        case "PageServiceDashboard":
          if (
            this.currentService &&
            this.serviceKey !== this.currentService.key
          ) {
            this.$router.push({
              name: routeName,
              params: { serviceKey: this.currentService.key },
            });
          }
          break;

        case "PageConsoleDashboard":
          if (this.$route.name !== routeName) {
            this.$router.push({ name: routeName });
          }
          break;

        default:
          this.$router.push({
            name: routeName.name,
            params: routeName.params,
            query: routeName.query,
          });
          break;
      }
    },
    agreeCallback() {
      this.$router.push({
        name: "PageConsoleLogout",
      });
    },
    disagreeCallback() {
      //
    },
    toggleLogoutDialog(val) {
      if (val) {
        this.$refs.confirmPopup.open({
          title: this.confirmationTitle,
          message: this.confirmationMessage,
          options: {
            btnDisagree: "Cancel",
            btnAgree: "Logout",
          },
          agree: () => {
            this.agreeCallback();
          },
          disagree: () => {
            this.disagreeCallback();
          },
        });
      }
    },
    toggleSurveyDialog(val) {
      this.surveyModel = val;
    },
    assignAnswerFieldType(answerType) {
      let fieldType = null;
      switch (answerType) {
        case "checkbox":
          fieldType = [];
          break;
        default:
        case "radio":
        case "textarea":
          break;
      }

      return fieldType;
    },

    submitForm(answers) {
      this.apiSubmit.setParams({
        answers,
        userUuid: this.auth.uuid,
        surveyUuid: process.env.VUE_APP_SURVEY,
      });

      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
            });
          }

          if (status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "success",
            });

            // this.surveyModel = false;
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
<style>
.v-main__wrap,
.v-list-item--active {
  background-image: linear-gradient(
    to right,
    #d4d4d4,
    #e7e7e7,
    #f1f1f1,
    #daf0ff
  );
}
</style>
