import crm from "@/services/crm/config/menu.js";
import developer from "@/services/developer/config/menu.js";
import event from "@/services/event/config/menu.js";
import hrdc from "@/services/hrdc/config/menu.js";
import hrdc_einvoicing from "@/services/hrdc_einvoicing/config/menu.js";
import hrdc_tax_summit from "@/services/hrdc_tax_summit/config/menu.js";
import hrdc_tpdiy from "@/services/hrdc_tpdiy/config/menu.js";
import log from "@/services/log/config/menu.js";
import sso from "@/services/sso/config/menu.js";

export default {
  main: [
    {
      name: "My Workspace",
    },
    {
      type: "divider",
    },
  ],
  crm,
  developer,
  event,
  hrdc,
  hrdc_einvoicing,
  hrdc_tax_summit,
  hrdc_tpdiy,
  log,
  sso,
};
