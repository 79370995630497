export default {
  key: "HrdcTpdiySettingsAttachment",
  serviceKey: "hrdc_tpdiy",
  name: {
    singular: "Attachment",
    plural: "Attachments",
  },
  parent_route: "HrdcTpdiySettings",
  add: {
    name: "Attachment",
  },
  view: {
    name: "Attachment",
  },
};
