<template>
  <div>
    <v-row no-gutters>
      <v-col md="4">
        <div class="tb-select">
          <v-select
            style="border-radius: 4px 0px 0px 4px; border-right: 0px"
            v-model="column_name"
            :items="columns"
            item-text="title"
            item-value="field"
            label="Table Column"
            solo
            dense
            hide-details
            @change="changeSearchType"
          ></v-select>
        </div>
      </v-col>

      <v-col md="4">
        <div class="tb-text-filed tb-with-textfield-clear">
          <v-text-field
            v-model="value"
            label="Value"
            :type="this.valueInputType"
            solo
            dense
            clearable
            hide-details
            @click:clear="clearSearch"
            @keyup.native.enter="searchRecords"
          ></v-text-field>
        </div>
      </v-col>

      <v-col md="2" class="d-inline-flex">
        <v-btn
          tile
          :disabled="!value"
          @click="searchRecords"
          :color="HRDC_BLUE_THEME_COLOR"
          class="text-white"
        >
          Search
        </v-btn>
        <v-btn
          tile
          :disabled="!value"
          @click="clearSearch()"
          color="error lighten-1"
          style="border-left: 1px solid white"
        >
          Clear
        </v-btn>
      </v-col>
      <v-col md="2"> </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    setConditions: {
      type: Function,
    },
  },
  data: () => ({
    column_name: null,
    columns: [],
    filters: [],
    type: null,
    selectedFilters: [],
    value: null,
    valueInputType: "text",
    selectedViewFilteredData: [],
  }),
  watch: {
    selectedFilters() {
      this.updateConditions();
    },
  },
  created() {
    this.columns = this.$_.filter(this.model.browse.table.headers, (o) => {
      if (o.searchable != false) {
        return o;
      }
    });
    this.column_name = this.columns[0] ? this.columns[0].field : null;
    this.type = "like";

    const searchDataStore = this.$store.state[this.model.serviceKey]?.searchData;
    if (searchDataStore) {
      // Get search value from store.
      this.value = searchDataStore.value;
      this.column_name = searchDataStore.field;
      this.searchRecords();
    }
  },
  methods: {
    searchRecords() {
      this.selectedFilters = [];

      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.value = parseFloat(this.value);
          break;
        case "integer":
          this.value = parseInt(this.value, 10);
          break;
        case "boolean":
          this.value = this.value == "true" ? true : false;
          break;

        case "date":
          this.value = this.$moment(new Date(this.value)).format("YYYY-MM-DD");
          break;
      }

      let condition = {
        field: selectedColumn.field,
        type: this.type,
        value:
          selectedColumn.dataType == "date" ? new Date(this.value) : this.value,
      };

      this.selectedFilters.push(JSON.stringify(condition));
    },
    clearSearch() {
      this.selectedFilters = [];
      this.value = null;
    },
    updateConditions() {
      var conditions = [];
      for (var condition of this.selectedFilters) {
        conditions.push(JSON.parse(condition));
      }

      if (this.selectedViewFilteredData.length > 0) {
        conditions = conditions.concat(this.selectedViewFilteredData);
      }

      this.setConditions(conditions);
    },
    resetCondition() {
      this.value = null;
    },
    changeSearchType() {
      var selectedColumn = this.$_.find(this.columns, {
        field: this.column_name,
      });

      switch (selectedColumn.dataType) {
        case "float":
          this.type = "=";
          this.valueInputType = "number";
          break;
        case "integer":
          this.type = "=";
          this.valueInputType = "number";
          break;
        case "boolean":
          this.type = "=";
          this.valueInputType = "text";
          break;
        case "date":
          this.type = "=";
          this.valueInputType = "date";
          break;

        default:
          this.type = "like";
          this.valueInputType = "text";
          break;
      }
    },
    getFilterCondition(filterCondition) {
      this.selectedViewFilteredData = filterCondition;
    },
  },
};
</script>
