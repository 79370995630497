const PAY_AND_REFUND_CELL_COLOR = "#CFD0CF";
const CLAIM_BASIS_CELL_COLOR = "#ECECEC";
const COMPANY_UNUSUAL_COLOR = "#FA8072";
const SUCCESS_COLOR = "#B4D3B2";

import moment from "moment";
import stages from "@/objects/stages";

export default {
  key: "application",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Application",
    plural: "Applications",
  },
  parents: [
    //
  ],
  add: {
    name: "Add Application",
  },
  view: {
    name: "View Application",
  },
  edit: {
    name: "Edit Application",
  },
  browse: {
    table: {
      headers: [
        {
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: false,
        },
        {
          title: "#",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          width: 80,
          formatter: (cell) => {
            cell.getElement().classList.add("number-class");
            const rowData = cell.getData();
            let data = rowData.rownum;

            if (rowData.isApplicationDuplicate) {
              data += ` <i title="Application Duplicate" aria-hidden="true" class="v-icon notranslate mdi mdi-content-duplicate theme--light orange--text"></i>`;
            }

            if (rowData.isFlag) {
              data += ` <i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light red--text normal-flag"></i>`;
            }

            return data;
          },
        },
        {
          title: "Company",
          field: "company",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          // width: 300,
          formatter: (cell) => {
            const data = cell.getData();
            const companyName = data.oriCompanyName;

            // Set color danger if refund type is 70/30
            if (data.refundType == 2) {
              cell.getElement().style.backgroundColor = COMPANY_UNUSUAL_COLOR;
            }

            return `<span style="color:blue;">${companyName.toUpperCase()}</span>`;
          },
        },
        {
          title: "Client",
          field: "client",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          // widthGrow: 2,
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "Client Contact No.",
          field: "clientPicContactNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          headerSort: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "Customer Service",
          field: "csName",
          dataType: "onlyLikeEqualNotEqual",
          headerSort: false,
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          visible: false,
          formatter(cell) {
            let cs = cell.getValue();
            if (cs == "nul" || cs == undefined) {
              return "-";
            }
            return cs;
          },
        },
        {
          title: "Responder",
          field: "responderName",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
        },
        {
          title: "Payment Channel",
          field: "isPaymentMade",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter(cell) {
            let isPaymentMade = cell.getValue();
            if (isPaymentMade == true) {
              cell.getElement().style.backgroundColor =
                PAY_AND_REFUND_CELL_COLOR;
              isPaymentMade = "Pay & Refund";
            }
            if (isPaymentMade == false) {
              cell.getElement().style.backgroundColor = CLAIM_BASIS_CELL_COLOR;
              isPaymentMade = "Claim Basis";
            }

            return isPaymentMade;
          },
        },
        {
          title: "Price (RM)",
          field: "price",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          width: 100,
          formatter(cell) {
            return parseInt(cell.getValue()).toLocaleString();
          },
        },
        {
          title: "Claim ID",
          field: "claimId",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const claimId = cell.getValue();
            if (claimId) {
              return claimId;
            }
            return "-";
          },
        },
        {
          title: "Grant Approval No.",
          field: "grantApprovalNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const grantApprovalNo = cell.getValue();
            if (grantApprovalNo) {
              return grantApprovalNo;
            }
            return "-";
          },
        },
        {
          title: "Credit Note No.",
          field: "creditNoteNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const creditNoteNo = cell.getValue();
            if (creditNoteNo) {
              return creditNoteNo;
            }
            return "-";
          },
        },
        {
          title: "Client Invoice No.",
          field: "autoGeneratedClientInvoiceNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const clientInvoiceNo = cell.getValue();
            if (clientInvoiceNo) {
              return clientInvoiceNo;
            }
            return "-";
          },
        },
        {
          title: "HRDC Invoice",
          field: "manualInvoiceToHrdc",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const manualInvoiceToHrdc = cell.getValue();
            if (manualInvoiceToHrdc) {
              return manualInvoiceToHrdc;
            }
            return "-";
          },
        },
        {
          title: "Stages",
          field: "stages",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter(cell) {
            let stageName = cell.getValue();
            const cellData = cell.getData();
            const today = moment().startOf("day");
            let trainingEndDate = null;
            let grantApplyDate = null;
            const SENT_CONFIRMATION_EMAIL_TO_CLIENT = stages.find(
              (stage) => stage.nameSlug == "editApplication"
            ).id;
            const MANUAL_INVOICE_TO_HRDC = stages.find(
              (stage) => stage.nameSlug == "manualInvoiceToHrdc"
            ).id;

            switch (cellData.stageNameSlug) {
              case "clientRevertT3Form":
                stageName = generateStageName(cellData, "clientRevertT3Form");
                break;
              case "manualInvoiceToHrdc":
                if (cellData.stageId == MANUAL_INVOICE_TO_HRDC) {
                  trainingEndDate = moment(
                    cellData.trainingEndDate,
                    "YYYY-MM-DD HH:mm:ssZ"
                  );
                  // If subscription date (26-29), can only submit claim on 30th (SOP to avoid HRDC query).
                  if (
                    trainingEndDate.isAfter(today) ||
                    trainingEndDate.isSame(today)
                  ) {
                    cell.getElement().style.backgroundColor =
                      COMPANY_UNUSUAL_COLOR;
                  } else {
                    cell.getElement().style.backgroundColor = SUCCESS_COLOR;
                  }
                }

                break;

              case "sentConfirmationEmailToClient":
                if (cellData.stageId == SENT_CONFIRMATION_EMAIL_TO_CLIENT) {
                  let cellColor = SUCCESS_COLOR;
                  if (cellData.grantApplyDate) {
                    grantApplyDate = moment(
                      cellData.grantApplyDate,
                      "YYYY-MM-DD HH:mm:ssZ"
                    );

                    if (today.isAfter(grantApplyDate)) {
                      cellColor = COMPANY_UNUSUAL_COLOR;
                    }
                  }
                  cell.getElement().style.backgroundColor = cellColor;
                }

                stageName = generateStageName(
                  cellData,
                  "clientRevertFormIncomplete"
                );
                break;

              case "grantApproved":
                stageName = generateStageName(cellData, "grantApproved");
                break;

              case "formsCompleted":
                stageName = generateStageName(cellData, "formsCompleted");
                break;

              case "clientRevertFormIncomplete":
                stageName = generateStageName(
                  cellData,
                  "clientRevertFormIncomplete"
                );
                break;

              case "claimApprovedFromHrdc":
                if (cellData.isPaymentReceivedPassGracePeriod == true) {
                  stageName += `
                    <i title="Late than 7 days received payment" aria-hidden="true" class="v-icon notranslate mdi mdi-information-slab-circle-outline theme--light black--text" style="cursor: pointer;"></i>
                  `;

                  cell.getElement().style.backgroundColor =
                    COMPANY_UNUSUAL_COLOR;
                }
                break;

              case "submittedClaimToHrdc":
                if (cellData.isHrdcClaimApprovalPassGracePeriod == true) {
                  stageName += `
                    <i title="Late than 4 days claim approval from HRDC" aria-hidden="true" class="v-icon notranslate mdi mdi-information-slab-circle-outline theme--light black--text" style="cursor: pointer;"></i>
                  `;

                  cell.getElement().style.backgroundColor =
                    COMPANY_UNUSUAL_COLOR;
                }

                break;

              case "refundEmailApToCimbRejected":
                cell.getElement().style.backgroundColor = COMPANY_UNUSUAL_COLOR;
                break;
            }
            return stageName;
          },
        },
        {
          title: "Revert Form Status",
          field: "revertFormStatus",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          visible: false,
          formatter(cell) {
            let cellStatus = cell.getValue();
            let revertStatus = null;
            if (cellStatus) {
              revertStatus = "Completed";
            } else {
              revertStatus = "Incompleted";
            }

            return revertStatus;
          },
        },
        {
          title: "Order By Refund Email Submission Date",
          field: "REFUND_EMAIL_SUBMISSION",
          dataType: "boolean",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: false,
          hozAlign: "left",
          headerWordWrap: false,
          showByDefault: false,
        },
        {
          title: "Order By Refund Email Approval 1 Date",
          field: "REFUND_EMAIL_APPROVAL_1",
          dataType: "boolean",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: false,
          hozAlign: "left",
          headerWordWrap: false,
          showByDefault: false,
        },
        {
          title: "Order By Refund Email Approval 2 Date",
          field: "REFUND_EMAIL_APPROVAL_2",
          dataType: "boolean",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: false,
          hozAlign: "left",
          headerWordWrap: false,
          showByDefault: false,
        },
        {
          title: "Manual Invoice Ready",
          field: "manualInvoiceReady",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          visible: false,
        },
        {
          title: "Submission Date",
          field: "timestampCreated",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter: (cell) => {
            const value = cell.getValue();
            let createdDate = moment(value).format("YYYY-MM-DD HH:mm");
            return createdDate;
          },
        },
        {
          title: "Payment Received Expired",
          field: "paymentReceivedExpired",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "Finance AP Sent Date",
          field: "orderByRefundEmailFinanceAp",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter: (cell) => {
            const value = cell.getValue();

            if (value) {
              let date = moment(value).format("YYYY-MM-DD HH:mm");
              return date;
            }

            return "-";
          },
        },
        {
          title: "Completed Date",
          field: "completedTime",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              let createdDate = moment(value).format("YYYY-MM-DD HH:mm");
              return createdDate;
            }

            return "-";
          },
        },
        {
          title: "Claim Submit Date",
          field: "claimSubmitDate",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              return value;
            }

            return "-";
          },
        },
        {
          title: "Claim Approved Date",
          field: "claimApprovedDate",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              return moment(value).format("YYYY-MM-DD");
            }

            return "-";
          },
        },
      ],
    },
  },
  crmViewKey: "tax_summit_application",
};

function generateAlert(type, title, content) {
  const alertTypeClasses = {
    success: "success--text",
    warning: "warning--text",
    error: "error--text",
  };

  const iconPath = {
    success: "mdi mdi-check-circle",
    error: "mdi mdi-close-circle",
    warning: "mdi mdi-alert-circle",
  };

  return `
    <i aria-hidden="true" class="v-icon notranslate ${iconPath[type]} theme--light ${alertTypeClasses[type]}" style="cursor: pointer;" title="${title}"></i>
      ${content}
     <br/>
  `;
}

function generateStageName(cellData, caseType) {
  let stageName = `<span>`;

  switch (caseType) {
    case "grantApproved":
      if (cellData.isJd14FormSubmitted || cellData.isT3FormSubmitted) {
        stageName += generateAlert(
          "warning",
          "JD14 & T3 Form is submitted but got issue",
          "JD14 & T3 Form"
        );
      } else {
        stageName += generateAlert(
          "error",
          "JD14 & T3 Form is not submit",
          "JD14 & T3 Form"
        );
      }

      stageName += generateAlert(
        "success",
        "Grant No. is completed",
        "Grant No."
      );

      if (cellData.isAttendanceFormSubmitted) {
        stageName += generateAlert(
          "success",
          "Attendance Form is completed",
          "Attendance Form"
        );
      } else {
        stageName += generateAlert(
          "error",
          "Attendance Form is not submit",
          "Attendance Form"
        );
      }
      break;

    case "formsCompleted":
      stageName += generateAlert(
        "success",
        "JD14 & T3 Form is completed",
        "JD14 & T3 Form"
      );

      if (cellData.grantApprovalNo) {
        stageName += generateAlert(
          "warning",
          "Grant No. is submitted but got issue",
          "Grant No."
        );
      } else {
        stageName += generateAlert(
          "error",
          "Grant No.is submitted but got issue ",
          "Grant No."
        );
      }

      if (cellData.isAttendanceFormSubmitted) {
        stageName += generateAlert(
          "success",
          "Attendance Form is completed",
          "Attendance Form"
        );
      } else {
        stageName += generateAlert(
          "error",
          "Attendance Form is not submit",
          "Attendance Form"
        );
      }
      break;

    case "clientRevertFormIncomplete":
      if (cellData.isJd14FormSubmitted || cellData.isT3FormSubmitted) {
        stageName += generateAlert(
          "warning",
          "JD14 & T3 Form is submitted but got issue",
          "JD14 & T3 Form"
        );
      } else {
        stageName += generateAlert(
          "error",
          "JD14 & T3 Form is not submit",
          "JD14 & T3 Form"
        );
      }

      if (cellData.grantApprovalNo) {
        stageName += generateAlert(
          "warning",
          "Grant No. is submitted but got issue",
          "Grant No."
        );
      } else {
        stageName += generateAlert(
          "error",
          "Grant No. is not submit",
          "Grant No."
        );
      }

      if (cellData.isAttendanceFormSubmitted) {
        stageName += generateAlert(
          "success",
          "Attendance Form is completed",
          "Attendance Form"
        );
      } else {
        stageName += generateAlert(
          "error",
          "Attendance Form is not submit",
          "Attendance Form"
        );
      }
      break;

    case "clientRevertT3Form":
      stageName += generateAlert(
        "success",
        "JD14 & T3 Form is completed",
        "JD14 & T3 Form"
      );

      stageName += generateAlert(
        "success",
        "Grant No. is completed",
        "Grant No."
      );

      stageName += generateAlert(
        "success",
        "Attendance Form is completed",
        "Attendance Form"
      );
      break;
  }

  stageName += `</span>`;
  return stageName;
}
