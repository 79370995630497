import Vue from "vue";
import VueRouter from "vue-router";
import LayoutBlank from "@/components/layouts/LayoutBlank.vue";
import LayoutAuthBlank from "@/components/layouts/LayoutAuthBlank.vue";
import LayoutConsoleV2 from "@/components/layouts/LayoutConsoleV2.vue";
import routerDeveloper from "@/services/developer/router/index";
import routerSSO from "@/services/sso/router/index";
import routerHrdc from "@/services/hrdc/routes/index";
import routerHrdcEinvoicing from "@/services/hrdc_einvoicing/routes/index";
import routerHrdcTaxSummit from "@/services/hrdc_tax_summit/routes/index";
import routerHrdcTpdiy from "@/services/hrdc_tpdiy/routes/index";
import routerLog from "@/services/log/router/index";
import store from "@/store/index";
import multiguard from "vue-router-multiguard";

const limitToGuest = function (to, from, next) {
  if (store.state.auth.data.token) next({ name: "PageConsoleDashboard" });
  next();
};
const limitToUser = function (to, from, next) {
  if (!store.state.auth.data.token) next({ name: "PageGuestHome" });
  next();
};

Vue.use(VueRouter);

//BOC:[locale]
import configLocale from "@/config/locale";
import _ from "lodash";
var pathArray = window.location.pathname.split("/");
var locale = pathArray[1];
var path = pathArray[2];
if (locale && _.findIndex(configLocale, { value: locale }) == -1) {
  //fallback to first available locale
  var url = window.location.href;
  var urlWithoutPath = url.replace(window.location.pathname, "");
  window.location.href = `${urlWithoutPath}/${configLocale[0].value}/${path}`;
} else if (locale && (path == null || path == "")) {
  window.location.href = window.location.origin;
}
//EOC

const routes = [
  {
    path: "/",
    component: LayoutBlank,
    children: [
      {
        path: "",
        name: "PageGuestLanding",
        component: () => import("@/views/guest/PageGuestLanding.vue"),
      },
    ],
  },
  {
    path: "/",
    component: LayoutAuthBlank,
    beforeEnter: multiguard([limitToGuest]),
    children: [
      {
        path: "home",
        name: "PageGuestHome",
        component: () => import("@/views/guest/PageGuestHome.vue"),
        props: true,
      },
      {
        path: "login/firstTime",
        name: "PageGuestLoginFirstTime",
        component: () => import("@/views/guest/PageGuestLoginFirstTime.vue"),
        props: true,
      },
      {
        path: "login/firstTime/setPassword",
        name: "PageGuestLoginFirstTimeSetPassword",
        component: () =>
          import("@/views/guest/PageGuestLoginFirstTimeSetPassword.vue"),
        props: true,
      },

      {
        path: "login/resetPassword",
        name: "PageResetPassword",
        component: () => import("@/views/guest/PageResetPassword.vue"),
        props: true,
      },

      {
        path: "login/resetPassword/confirm",
        name: "PagePasswordConfirmation",
        component: () => import("@/views/guest/PagePasswordConfirmation.vue"),
        props: (route) => ({
          params: {
            token: route.query.token,
          },
        }),
      },
    ],
  },
  {
    path: "/",
    component: LayoutConsoleV2,
    beforeEnter: multiguard([limitToUser]),
    children: [
      routerDeveloper,
      routerHrdc,
      routerHrdcEinvoicing,
      routerHrdcTaxSummit,
      routerHrdcTpdiy,
      routerLog,
      routerSSO,
      {
        path: "service",
        name: "PageServiceBrowse",
        redirect: { name: "PageConsoleDashboard" },
      },
      {
        path: "service/:serviceKey",
        name: "PageServiceLanding",
        redirect: { path: "service/:serviceKey/dashboard" },
      },
      {
        path: "service/:serviceKey/redirect",
        name: "PageServiceRedirect",
        redirect: { path: "service/:serviceKey/dashboard" },
      },
      {
        path: "service/:serviceKey/dashboard",
        name: "PageServiceDashboard",
        component: () => import("@/views/service/PageServiceDashboard.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey",
        name: "PageServiceModelBrowse",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelBrowse.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/new",
        name: "PageServiceModelAdd",
        component: () => import("@/views/service/PageServiceModelAdd.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/:modelId/edit",
        name: "PageServiceModelEdit",
        component: () => import("@/views/service/PageServiceModelEdit.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/:modelId/delete",
        name: "PageServiceModelDelete",
        component: () => import("@/views/service/PageServiceModelDelete.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/:modelId/deleteHard",
        name: "PageServiceModelDeleteHard",
        component: () =>
          import("@/views/service/PageServiceModelDeleteHard.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/:modelId/restore",
        name: "PageServiceModelRestore",
        component: () => import("@/views/service/PageServiceModelRestore.vue"),
      },
      {
        path: "service/:serviceKey/model/:parentModelKey/:parentModelId/:childModelKey/new",
        name: "PageServiceModelChildAdd",
        component: () => import("@/views/service/PageServiceModelChildAdd.vue"),
      },
      {
        path: "service/:serviceKey/model/:parentModelKey/:parentModelId/:intermediateModelKey/:intermediateModelId/:childModelKey/detach",
        name: "PageServiceModelChildDetach",
        component: () =>
          import("@/views/service/PageServiceModelChildDetach.vue"),
      },
      {
        path: "service/:serviceKey/model/:modelKey/:modelId",
        name: "PageServiceModelRead",
        component: () => import("@/views/service/PageServiceModelRead.vue"),
      },
      // routes for tabulator pages
      {
        path: "service/:serviceKey/dev-model/:modelKey",
        name: "PageServiceModelBreadTabulatorBrowse",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelBrowse.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId/:childModelKey",
        name: "PageServiceModelBreadTabulatorBrowseParent",
        component: () =>
          import(
            "@/views/service/TabulatorPages/PageServiceModelBrowseParent.vue"
          ),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/new",
        name: "PageServiceModelBreadTabulatorAdd",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelAdd.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId",
        name: "PageServiceModelBreadTabulatorRead",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelRead.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId/item/edit",
        name: "PageServiceModelBreadTabulatorEdit",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelEdit.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId/item/delete",
        name: "PageServiceModelBreadTabulatorDelete",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelDelete.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:parentModelKey/:parentModelId/:childModelKey/new",
        name: "PageServiceModelBreadTabulatorChildAdd",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelChildAdd.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId/deleteHard",
        name: "PageServiceModelBreadTabulatorDeleteHard",
        component: () =>
          import(
            "@/views/service/TabulatorPages/PageServiceModelDeleteHard.vue"
          ),
      },
      {
        path: "service/:serviceKey/dev-model/:modelKey/:modelId/restore",
        name: "PageServiceModelBreadTabulatorRestore",
        component: () =>
          import("@/views/service/TabulatorPages/PageServiceModelRestore.vue"),
      },
      {
        path: "service/:serviceKey/dev-model/:parentModelKey/:parentModelId/:intermediateModelKey/:intermediateModelId/:childModelKey/detach",
        name: "PageServiceModelBreadTabulatorChildDetach",
        component: () =>
          import(
            "@/views/service/TabulatorPages/PageServiceModelChildDetach.vue"
          ),
      },
    ],
  },
  {
    path: "/console",
    component: LayoutConsoleV2,
    beforeEnter: multiguard([limitToUser]),
    children: [
      {
        path: "",
        redirect: { name: "PageConsoleDashboard" },
      },
      {
        path: "dashboard",
        name: "PageConsoleDashboard",
        component: () => import("@/views/console/PageConsoleDashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "logout",
        name: "PageConsoleLogout",
        component: () => import("@/views/console/PageConsoleLogout.vue"),
      },
    ],
  },
  {
    path: "",
    component: LayoutBlank,
    children: [
      {
        path: "error/expired",
        name: "PageErrorSessionExpired",
        component: () => import("@/views/error/PageErrorSessionExpired.vue"),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageErrorNotFound",
        component: () => import("@/views/error/PageErrorNotFound.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  base: locale,
  mode: "history",
  routes,
});

//BOC:[page title]
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " | YYC taxPOD"
      : "YYC taxPOD";
  });
});
//EOC

export default router;
