<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
        {{ title }}
      </v-toolbar>

      <div class="pa-4">{{ message }}</div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green lighten-1" class="text-light" @click="agree">
          {{ options.btnAgree }}
        </v-btn>

        <v-btn color="red lighten-1" class="text-light" @click="disagree">
          {{ options.btnDisagree }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  name: "ConfirmPopup",
  data() {
    return {
      hrdcData: new HrdcData(),
      dialog: false,
      title: "",
      message: "",
      options: {
        btnDisagree: "Cancel",
        btnAgree: "Submit",
      },
      agreeCallback: null,
      disagreeCallback: null,
    };
  },
  methods: {
    open({
      title,
      message,
      options = {
        btnDisagree: "Cancel",
        btnAgree: "Submit",
      },
      agree = () => {},
      disagree = () => {},
    }) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = options;
      this.agreeCallback = agree;
      this.disagreeCallback = disagree;
    },
    disagree() {
      this.dialog = false;
      this.disagreeCallback();
    },
    agree() {
      this.dialog = false;
      this.agreeCallback();
    },
  },
};
</script>
