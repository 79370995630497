const stagesArr = [
  {
    id: 1,
    overviewName: null,
    name: "Edit Application",
    nameSlug: "editApplication",
    stagePriority: 1,
    timestampCreated: "2024-01-18T14:18:32.268Z",
    timestampUpdated: "2024-10-24T11:38:22.463Z",
    timestampDeleted: null,
  },
  {
    id: 2,
    overviewName: null,
    name: "Delete Application",
    nameSlug: "deleteApplication",
    stagePriority: 2,
    timestampCreated: "2024-01-18T14:18:32.334Z",
    timestampUpdated: "2024-10-24T11:38:22.479Z",
    timestampDeleted: null,
  },
  {
    id: 3,
    overviewName: "Client Grant Application",
    name: "Application Submitted",
    nameSlug: "applicationSubmitted",
    stagePriority: 100,
    timestampCreated: "2024-01-18T14:18:32.344Z",
    timestampUpdated: "2024-10-24T11:38:22.491Z",
    timestampDeleted: null,
  },
  {
    id: 4,
    overviewName: "Client Grant Application",
    name: "Application Approved",
    nameSlug: "applicationApproved",
    stagePriority: 200,
    timestampCreated: "2024-01-18T14:18:32.355Z",
    timestampUpdated: "2024-10-24T11:38:22.502Z",
    timestampDeleted: null,
  },
  {
    id: 5,
    overviewName: "Client Grant Application",
    name: "Quotation Date Scheduled",
    nameSlug: "quotationDateScheduled",
    stagePriority: 300,
    timestampCreated: "2024-01-18T14:18:32.367Z",
    timestampUpdated: "2024-10-24T11:38:22.514Z",
    timestampDeleted: null,
  },
  {
    id: 6,
    overviewName: "Client Grant Application",
    name: "Subscription Date Scheduled",
    nameSlug: "subscriptionDateScheduled",
    stagePriority: 400,
    timestampCreated: "2024-01-18T14:18:32.378Z",
    timestampUpdated: "2024-10-24T11:38:22.526Z",
    timestampDeleted: null,
  },
  {
    id: 7,
    overviewName: "Client Grant Application",
    name: "Sent JD14 Form To Client",
    nameSlug: "sentJd14FormToClient",
    stagePriority: 500,
    timestampCreated: "2024-01-18T14:18:32.390Z",
    timestampUpdated: "2024-10-24T11:38:22.537Z",
    timestampDeleted: null,
  },
  {
    id: 8,
    overviewName: "Client Grant Application",
    name: "Sent T3 Form To Client",
    nameSlug: "sentT3FormToClient",
    stagePriority: 600,
    timestampCreated: "2024-01-18T14:18:32.397Z",
    timestampUpdated: "2024-10-24T11:38:22.548Z",
    timestampDeleted: null,
  },
  {
    id: 9,
    overviewName: "Client Grant Application",
    name: "Sent Confirmation Email To Client",
    nameSlug: "sentConfirmationEmailToClient",
    stagePriority: 700,
    timestampCreated: "2024-01-18T14:18:32.405Z",
    timestampUpdated: "2024-10-24T11:38:22.559Z",
    timestampDeleted: null,
  },
  {
    id: 10,
    overviewName: "Client Grant Application",
    name: "Alert HRDC Admin To Resend Email To Client",
    nameSlug: "alertHrdcAdminResendEmailToClient",
    stagePriority: 800,
    timestampCreated: "2024-01-18T14:18:32.416Z",
    timestampUpdated: "2024-10-24T11:38:22.570Z",
    timestampDeleted: null,
  },
  {
    id: 11,
    overviewName: "Client Grant Application",
    name: "Grant Approval No Submitted",
    nameSlug: "grantApprovalNoSubmitted",
    stagePriority: 900,
    timestampCreated: "2024-01-18T14:18:32.426Z",
    timestampUpdated: "2024-10-24T11:38:22.582Z",
    timestampDeleted: null,
  },
  {
    id: 12,
    overviewName: "Client Grant Application",
    name: "(Incomplete) Grant Approval & Revert Form",
    nameSlug: "clientRevertFormIncomplete",
    stagePriority: 1000,
    timestampCreated: "2024-01-18T14:18:32.438Z",
    timestampUpdated: "2024-10-24T11:38:22.593Z",
    timestampDeleted: null,
  },
  {
    id: 13,
    overviewName: "Client Grant Application",
    name: "Client Revert JD14 Form",
    nameSlug: "clientRevertJd14Form",
    stagePriority: 1100,
    timestampCreated: "2024-01-18T14:18:32.447Z",
    timestampUpdated: "2024-10-24T11:38:22.603Z",
    timestampDeleted: null,
  },
  {
    id: 14,
    overviewName: "Client Grant Application",
    name: "Client Revert T3 Form",
    nameSlug: "clientRevertT3Form",
    stagePriority: 1200,
    timestampCreated: "2024-01-18T14:18:32.458Z",
    timestampUpdated: "2024-10-24T11:38:22.615Z",
    timestampDeleted: null,
  },
  {
    id: 15,
    overviewName: "YYC Claim Submission",
    name: "Invoice Date Scheduled",
    nameSlug: "invoiceDateScheduled",
    stagePriority: 1300,
    timestampCreated: "2024-01-18T14:18:32.468Z",
    timestampUpdated: "2024-10-24T11:38:22.632Z",
    timestampDeleted: null,
  },
  {
    id: 16,
    overviewName: "YYC Claim Submission",
    name: "Manual Invoice To HRDC",
    nameSlug: "manualInvoiceToHrdc",
    stagePriority: 1400,
    timestampCreated: "2024-01-18T14:18:32.477Z",
    timestampUpdated: "2024-10-24T11:38:22.643Z",
    timestampDeleted: null,
  },
  {
    id: 17,
    overviewName: "YYC Claim Submission",
    name: "Submitted Claim to HRDC",
    nameSlug: "submittedClaimToHrdc",
    stagePriority: 1500,
    timestampCreated: "2024-01-18T14:18:32.487Z",
    timestampUpdated: "2024-10-24T11:38:22.654Z",
    timestampDeleted: null,
  },
  {
    id: 18,
    overviewName: "YYC Claim Submission",
    name: "Claim Approved from HRDC",
    nameSlug: "claimApprovedFromHrdc",
    stagePriority: 1600,
    timestampCreated: "2024-01-18T14:18:32.498Z",
    timestampUpdated: "2024-10-24T11:38:22.664Z",
    timestampDeleted: null,
  },
  {
    id: 19,
    overviewName: "YYC Claim Submission",
    name: "Payment Received from HRDC",
    nameSlug: "paymentReceivedFromHrdc",
    stagePriority: 1700,
    timestampCreated: "2024-01-18T14:18:32.509Z",
    timestampUpdated: "2024-10-24T11:38:22.674Z",
    timestampDeleted: null,
  },
  {
    id: 20,
    overviewName: "YYC Claim Submission",
    name: "Credit Note Requested",
    nameSlug: "creditNoteRequested",
    stagePriority: 1800,
    timestampCreated: "2024-01-18T14:18:32.519Z",
    timestampUpdated: "2024-10-24T11:38:22.686Z",
    timestampDeleted: null,
  },
  {
    id: 21,
    overviewName: "YYC Claim Submission",
    name: "Credit Note Documented",
    nameSlug: "creditNoteDocumented",
    stagePriority: 1900,
    timestampCreated: "2024-01-18T14:18:32.528Z",
    timestampUpdated: "2024-10-24T11:38:22.696Z",
    timestampDeleted: null,
  },
  {
    id: 22,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Submission",
    nameSlug: "refundEmailSubmission",
    stagePriority: 2000,
    timestampCreated: "2024-01-18T14:18:32.540Z",
    timestampUpdated: "2024-10-24T11:38:22.706Z",
    timestampDeleted: null,
  },
  {
    id: 23,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Updated",
    nameSlug: "refundEmailUpdated",
    stagePriority: 2100,
    timestampCreated: "2024-01-18T14:18:32.549Z",
    timestampUpdated: "2024-10-24T11:38:22.716Z",
    timestampDeleted: null,
  },
  {
    id: 24,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Approval 1",
    nameSlug: "refundEmailApproval1",
    stagePriority: 2200,
    timestampCreated: "2024-01-18T14:18:32.557Z",
    timestampUpdated: "2024-10-24T11:38:22.727Z",
    timestampDeleted: null,
  },
  {
    id: 25,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Approval 2",
    nameSlug: "refundEmailApproval2",
    stagePriority: 2300,
    timestampCreated: "2024-01-18T14:18:32.568Z",
    timestampUpdated: "2024-10-24T11:38:22.739Z",
    timestampDeleted: null,
  },
  {
    id: 26,
    overviewName: "YYC Claim Submission",
    name: "Refund Email AR to AP",
    nameSlug: "refundEmailArToAp",
    stagePriority: 2400,
    timestampCreated: "2024-01-18T14:18:32.579Z",
    timestampUpdated: "2024-10-24T11:38:22.751Z",
    timestampDeleted: null,
  },
  {
    id: 27,
    overviewName: "YYC Claim Submission",
    name: "Refund Failed",
    nameSlug: "refundFailed",
    stagePriority: 2500,
    timestampCreated: "2024-01-18T14:18:32.590Z",
    timestampUpdated: "2024-10-24T11:38:22.774Z",
    timestampDeleted: null,
  },
  {
    id: 28,
    overviewName: "YYC Claim Submission",
    name: "Finance Refund to Client",
    nameSlug: "financeRefundToClient",
    stagePriority: 2600,
    timestampCreated: "2024-01-18T14:18:32.599Z",
    timestampUpdated: "2024-10-24T11:38:22.785Z",
    timestampDeleted: null,
  },
  {
    id: 29,
    overviewName: "YYC Claim Submission",
    name: "Email Payment Advice to Client",
    nameSlug: "emailPaymentAdviceToClient",
    stagePriority: 2700,
    timestampCreated: "2024-01-18T14:18:32.612Z",
    timestampUpdated: "2024-10-24T11:38:22.795Z",
    timestampDeleted: null,
  },
  {
    id: 30,
    overviewName: "YYC Claim Submission",
    name: "Sent Refund Email",
    nameSlug: "sentRefundEmail",
    stagePriority: 2800,
    timestampCreated: "2024-01-18T14:18:32.622Z",
    timestampUpdated: "2024-10-24T11:38:22.805Z",
    timestampDeleted: null,
  },
  {
    id: 31,
    overviewName: "YYC Claim Submission",
    name: "TaxPOD Account Activation",
    nameSlug: "taxpodAccountActivation",
    stagePriority: 2900,
    timestampCreated: "2024-01-18T14:18:32.630Z",
    timestampUpdated: "2024-10-24T11:38:22.816Z",
    timestampDeleted: null,
  },
  {
    id: 32,
    overviewName: "YYC Claim Submission",
    name: "Claim Completed",
    nameSlug: "hrdcClaimCompleted",
    stagePriority: 3000,
    timestampCreated: "2024-01-18T14:18:32.639Z",
    timestampUpdated: "2024-10-24T11:38:22.826Z",
    timestampDeleted: null,
  },
  {
    id: 33,
    overviewName: "YYC Claim Submission",
    name: "Auto Generated TaxPOD Invoice",
    nameSlug: "autoGeneratedTaxpodInvoice",
    stagePriority: 2005,
    timestampCreated: "2024-01-18T14:18:32.650Z",
    timestampUpdated: "2024-10-24T11:38:22.837Z",
    timestampDeleted: null,
  },
  {
    id: 34,
    overviewName: "YYC Claim Submission",
    name: "Credit Note",
    nameSlug: "creditNote",
    stagePriority: 2010,
    timestampCreated: "2024-01-18T14:18:32.662Z",
    timestampUpdated: "2024-10-24T11:38:22.847Z",
    timestampDeleted: null,
  },
  {
    id: 35,
    overviewName: "YYC Claim Submission",
    name: "Payment Advice From HRDC",
    nameSlug: "paymentAdviceFromHrdc",
    stagePriority: 2015,
    timestampCreated: "2024-01-18T14:18:32.670Z",
    timestampUpdated: "2024-10-24T11:38:22.859Z",
    timestampDeleted: null,
  },
  {
    id: 36,
    overviewName: "YYC Claim Submission",
    name: "Payment Advice From Client",
    nameSlug: "paymentAdviceFromClient",
    stagePriority: 2020,
    timestampCreated: "2024-01-18T14:18:32.679Z",
    timestampUpdated: "2024-10-24T11:38:22.868Z",
    timestampDeleted: null,
  },
  {
    id: 37,
    overviewName: "YYC Claim Submission",
    name: "Application Dead",
    nameSlug: "applicationDead",
    stagePriority: 3,
    timestampCreated: "2024-01-18T14:18:32.689Z",
    timestampUpdated: "2024-10-24T11:38:22.884Z",
    timestampDeleted: null,
  },
  {
    id: 38,
    overviewName: "YYC Claim Submission",
    name: "Application Duplicate",
    nameSlug: "applicationDuplicate",
    stagePriority: 4,
    timestampCreated: "2024-01-18T14:18:32.699Z",
    timestampUpdated: "2024-10-24T11:38:22.894Z",
    timestampDeleted: null,
  },
  {
    id: 39,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Updated 2",
    nameSlug: "refundEmailUpdated2",
    stagePriority: 2105,
    timestampCreated: "2024-01-18T14:18:32.709Z",
    timestampUpdated: "2024-10-24T11:38:22.905Z",
    timestampDeleted: null,
  },
  {
    id: 40,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Other Attachments",
    nameSlug: "refundEmailOtherAttachments",
    stagePriority: 2025,
    timestampCreated: "2024-01-30T08:45:38.230Z",
    timestampUpdated: "2024-10-24T11:38:22.915Z",
    timestampDeleted: null,
  },
  {
    id: 41,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Approval 1 Rejected",
    nameSlug: "refundEmailApproval1Rejected",
    stagePriority: 2195,
    timestampCreated: "2024-01-30T08:45:38.243Z",
    timestampUpdated: "2024-10-24T11:38:22.926Z",
    timestampDeleted: null,
  },
  {
    id: 42,
    overviewName: "YYC Claim Submission",
    name: "Refund Email Approval 2 Rejected",
    nameSlug: "refundEmailApproval2Rejected",
    stagePriority: 2295,
    timestampCreated: "2024-01-30T08:45:38.252Z",
    timestampUpdated: "2024-10-24T11:38:22.936Z",
    timestampDeleted: null,
  },
  {
    id: 43,
    overviewName: "YYC Claim Submission",
    name: "JD14 T3 Form Submitted",
    nameSlug: "jd14T3FormSubmitted",
    stagePriority: 901,
    timestampCreated: "2024-02-03T16:24:24.817Z",
    timestampUpdated: "2024-10-24T11:38:22.969Z",
    timestampDeleted: null,
  },
  {
    id: 44,
    overviewName: "YYC Claim Submission",
    name: "(Incomplete) Grant Approved",
    nameSlug: "grantApproved",
    stagePriority: 920,
    timestampCreated: "2024-02-03T16:24:24.825Z",
    timestampUpdated: "2024-10-24T11:38:22.980Z",
    timestampDeleted: null,
  },
  {
    id: 45,
    overviewName: "YYC Claim Submission",
    name: "(Incomplete) Forms Completed",
    nameSlug: "formsCompleted",
    stagePriority: 940,
    timestampCreated: "2024-02-03T16:24:24.834Z",
    timestampUpdated: "2024-10-24T11:38:22.990Z",
    timestampDeleted: null,
  },
  {
    id: 46,
    overviewName: "YYC Claim Submission",
    name: "General Info Remarks",
    nameSlug: "generalInfoRemarks",
    stagePriority: 5,
    timestampCreated: "2024-02-27T12:48:13.694Z",
    timestampUpdated: "2024-10-24T11:38:23.015Z",
    timestampDeleted: null,
  },
  {
    id: 47,
    overviewName: "YYC Claim Submission",
    name: "Payment Received from HRDC 2",
    nameSlug: "paymentReceivedFromHrdc2",
    stagePriority: 1699,
    timestampCreated: "2024-02-27T12:59:53.271Z",
    timestampUpdated: "2024-10-24T11:38:23.025Z",
    timestampDeleted: null,
  },
  {
    id: 48,
    overviewName: "YYC Claim Submission",
    name: "Credit Note Generated",
    nameSlug: "creditNoteGenerated",
    stagePriority: 1850,
    timestampCreated: "2024-04-04T05:19:10.870Z",
    timestampUpdated: "2024-10-24T11:38:23.001Z",
    timestampDeleted: null,
  },
  {
    id: 49,
    overviewName: null,
    name: "General Attachment",
    nameSlug: "generalAttachment",
    stagePriority: 6,
    timestampCreated: "2024-04-09T07:24:29.461Z",
    timestampUpdated: "2024-10-24T11:38:23.036Z",
    timestampDeleted: null,
  },
  {
    id: 50,
    overviewName: null,
    name: "Normal Flag",
    nameSlug: "normalFlag",
    stagePriority: 7,
    timestampCreated: "2024-04-17T15:44:23.100Z",
    timestampUpdated: "2024-10-24T11:38:23.046Z",
    timestampDeleted: null,
  },
  {
    id: 51,
    overviewName: null,
    name: "Normal Unflag",
    nameSlug: "normalUnflag",
    stagePriority: 8,
    timestampCreated: "2024-04-17T15:44:23.115Z",
    timestampUpdated: "2024-10-24T11:38:23.057Z",
    timestampDeleted: null,
  },
  {
    id: 52,
    overviewName: null,
    name: "Flag Remarks",
    nameSlug: "flagRemarks",
    stagePriority: 11,
    timestampCreated: "2024-04-17T15:44:23.125Z",
    timestampUpdated: "2024-10-24T11:38:23.068Z",
    timestampDeleted: null,
  },
  {
    id: 53,
    overviewName: "Client Grant Application",
    name: "Application Duplicated",
    nameSlug: "applicationDuplicated",
    stagePriority: 50,
    timestampCreated: "2024-05-08T05:51:31.958Z",
    timestampUpdated: "2024-10-24T11:38:23.078Z",
    timestampDeleted: null,
  },
  {
    id: 54,
    overviewName: null,
    name: "HRDC Grant Email",
    nameSlug: "hrdcGrantEmail",
    stagePriority: 880,
    timestampCreated: "2024-05-15T07:47:15.751Z",
    timestampUpdated: "2024-10-24T11:38:23.089Z",
    timestampDeleted: null,
  },
  {
    id: 55,
    overviewName: "YYC Claim Submission",
    name: "AP Submit Refund to CIMB",
    nameSlug: "apSubmitRefundToCimb",
    stagePriority: 2450,
    timestampCreated: "2024-07-04T04:41:49.455Z",
    timestampUpdated: "2024-10-24T11:38:22.761Z",
    timestampDeleted: null,
  },
  {
    id: 56,
    overviewName: "YYC Claim Submission",
    name: "Refund Email AR to AP Rejected",
    nameSlug: "refundEmailArToApRejected",
    stagePriority: 2395,
    timestampCreated: "2024-07-04T04:41:49.641Z",
    timestampUpdated: "2024-10-24T11:38:22.945Z",
    timestampDeleted: null,
  },
  {
    id: 57,
    overviewName: "YYC Claim Submission",
    name: "Payment Received from HRDC Rejected",
    nameSlug: "paymentReceivedFromHrdcRejected",
    stagePriority: 2495,
    timestampCreated: "2024-07-04T04:41:49.650Z",
    timestampUpdated: "2024-10-24T11:38:22.955Z",
    timestampDeleted: null,
  },
  {
    id: 58,
    overviewName: null,
    name: "Refund Email AP to CIMB Rejected",
    nameSlug: "refundEmailApToCimbRejected",
    stagePriority: 2440,
    timestampCreated: "2024-08-05T12:23:58.976Z",
    timestampUpdated: "2024-10-24T11:38:23.108Z",
    timestampDeleted: null,
  },
  {
    id: 59,
    overviewName: null,
    name: "Payment Voucher",
    nameSlug: "paymentVoucher",
    stagePriority: 60,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
  {
    id: 60,
    overviewName: null,
    name: "Attendance Form",
    nameSlug: "attendanceForm",
    stagePriority: 70,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
  {
    id: 61,
    overviewName: null,
    name: "Attendance List",
    nameSlug: "attendanceList",
    stagePriority: 71,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
  {
    id: 62,
    overviewName: null,
    name: "Delete Attachment",
    nameSlug: "deleteAttachment",
    stagePriority: 9,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
  {
    id: 63,
    overviewName: null,
    name: "Other Attachment",
    nameSlug: "otherAttachment",
    stagePriority: 10,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
  {
    id: 64,
    overviewName: null,
    name: "Bulk Event Application",
    nameSlug: "bulkEventApplication",
    stagePriority: 72,
    timestampCreated: "2024-10-08T11:11:23.252Z",
    timestampUpdated: "2024-10-24T11:38:23.120Z",
    timestampDeleted: null,
  },
];
export default stagesArr.sort((a, b) => a.id - b.id);
