<template>
  <v-dialog v-model="surveyModel" persistent width="700">
    <v-card>
      <v-card-title
        class="text-h5 text-white"
        style="background-color: #063058"
      >
        {{ surveyDetails.title }}
      </v-card-title>

      <v-img
        src="https://media.giphy.com/media/QMkPpxPDYY0fu/giphy.gif?cid=790b7611jb3joqmj45gh5xpfrj4cg2menak3teh3criz8yav&ep=v1_gifs_search&rid=giphy.gif&ct=g"
      ></v-img>

      <!-- Page 1 -->
      <div class="pa-5 text-center text-h5" v-show="currentPage == 1">
        <p>
          🎉It's been nearly one year since the launch of the HRDC, as part of
          our commitment to continuously improve, we're reaching out to gather
          your feedback during this period.🎉
        </p>
        <p>Please take moments to share your thoughts with us.😊</p>
        <p>Thanks for being part of this journey! 🙏</p>
        <div class="text-h6 text-right">
          <i>-taxPOD Product Team</i>
        </div>
      </div>

      <!-- Page Question -->
      <div
        class="pa-5"
        v-for="(item, key) in surveyQuestions"
        :key="key"
        v-show="currentPage == key + 2"
      >
        <v-card-title class="text-center">
          {{ item.question }}
        </v-card-title>
        <!-- Radio -->
        <v-card-text v-if="item.answerType == 'radio'">
          <v-radio-group
            v-model="surveyAnswer[item.uuid]"
            :error-messages="errorField[item.uuid]"
            @change="trackQuestion(item.uuid)"
          >
            <v-radio
              v-for="(option, optKey) in item.SurveyOption"
              :key="optKey"
              :value="option.option"
              :label="option.option"
              :color="HRDC_BLUE_THEME_COLOR"
              class="text-center"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <!-- Checkbox -->
        <v-card-text v-if="item.answerType == 'checkbox'">
          <v-checkbox
            v-model="surveyAnswer[item.uuid]"
            v-for="(option, optKey) in item.SurveyOption"
            :key="optKey"
            :label="option.option"
            :value="option.option"
            :color="HRDC_BLUE_THEME_COLOR"
            hide-details
            :error-messages="errorField[item.uuid]"
            @change="trackQuestion(item.uuid)"
          ></v-checkbox>
        </v-card-text>
        <!-- Textarea -->
        <v-card-text v-if="item.answerType == 'textarea'">
          <v-textarea
            v-model="surveyAnswer[item.uuid]"
            filled
            auto-grow
            :error-messages="errorField[item.uuid]"
            @input="trackQuestion(item.uuid)"
          ></v-textarea>
        </v-card-text>
      </div>

      <div class="pa-5 text-center text-h5" v-show="isThankYouPage">
        <p>Thanks for sharing your thoughts with us!🙏🙏🙏</p>
      </div>

      <hr />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentPage > 1 && !isThankYouPage"
          :color="HRDC_BLUE_THEME_COLOR"
          icon
          @click="togglePage(false)"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
          v-if="!isLastPage && !isThankYouPage"
          :color="HRDC_BLUE_THEME_COLOR"
          icon
          @click="togglePage(true)"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
        <!-- Submit button -->
        <v-btn
          v-if="isLastPage"
          color="green"
          elevation="0"
          dark
          @click="submitFormChildren()"
        >
          Submit
        </v-btn>
        <!-- Close Modal button -->
        <v-btn
          v-if="isThankYouPage"
          :color="HRDC_BLUE_THEME_COLOR"
          elevation="0"
          dark
          @click="toggleSurveyDialog(false)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Survey",
  props: {
    surveyModel: Boolean,
    surveyQuestions: Array,
    surveyDetails: Object,
    surveyAnswer: Object,
    errorField: Object,
  },
  emits: ["toggleSurveyDialogChildren", "submitFormChildren"],
  computed: {
    isLastPage() {
      return this.surveyQuestions.length == this.currentPage - 1;
    },
    isThankYouPage() {
      return this.currentPage - 2 == this.surveyQuestions.length;
    },
  },
  data: () => ({
    currentPage: 1,
  }),
  methods: {
    toggleSurveyDialog(val) {
      this.$emit("toggleSurveyDialogChildren", val);
    },
    togglePage(toggle) {
      if (toggle) {
        // Check for answer filled before proceed next page (only check after page 1).
        if (this.currentPage !== 1) {
          const questionIndex = Object.keys(this.surveyAnswer)[
            this.currentPage - 2
          ];
          const isValidate = this.validateInput(questionIndex);
          if (!isValidate) {
            this.errorField[questionIndex] = "This field is required";
            return false;
          }
        }
        this.currentPage++;
      }

      if (!toggle) {
        this.currentPage--;
      }
    },
    validateInput(questionIndex) {
      const question = this.surveyQuestions.find(
        (item) => item.uuid == questionIndex
      );
      const { answerType } = question;
      const answer = this.surveyAnswer[questionIndex];

      switch (answerType) {
        case "textarea":
        case "radio":
          if (!answer) {
            return false;
          }
          break;

        case "checkbox":
          if (answer.length == 0) {
            return false;
          }
          break;
      }

      return true;
    },
    trackQuestion(questionUuid) {
      const answer = this.surveyAnswer[questionUuid];

      const question = this.surveyQuestions.find(
        (item) => item.uuid == questionUuid
      );
      const { answerType } = question;

      switch (answerType) {
        default:
        case "textarea":
        case "radio":
          if (answer) {
            this.clearErrorMsg(questionUuid);
          }
          break;

        case "checkbox":
          if (answer.length > 0) {
            this.clearErrorMsg(questionUuid);
          }
          break;
      }
    },
    submitFormChildren() {
      // Before submit check for 1 last time.
      for (const question of this.surveyQuestions) {
        const val = this.validateInput(question.uuid);
        if (!val) {
          this.errorField[question.uuid] = "This field is required";
          return false;
        }
      }

      this.$emit("submitFormChildren", this.surveyAnswer);

      // Show thank you page.
      this.currentPage++;
    },
  },
};
</script>
