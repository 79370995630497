//BOC:[crm]
import crmService from "@/services/crm/models/crmService";
import crmServicePermission from "@/services/crm/models/crmServicePermission";
import crmServicePermissionToInternalRole from "@/services/crm/models/crmServicePermissionToInternalRole";
//EOC_template
//BOC:[log]
import logRequest from "@/services/log/models/logRequest";
//EOC
//BOC:[sso]
import internalCompany from "@/services/sso/models/internalCompany";
import internalDepartment from "@/services/sso/models/internalDepartment";
import internalRole from "@/services/sso/models/internalRole";
import internalUser from "@/services/sso/models/internalUser";
import internalRoleToInternalUser from "@/services/sso/models/internalRoleToInternalUser";
import ssoBusiness from "@/services/sso/models/ssoBusiness";
import ssoMicrosoftAccount from "@/services/sso/models/ssoMicrosoftAccount";
import ssoTeam from "@/services/sso/models/ssoTeam";
import ssoTeamToInternalUser from "@/services/sso/models/ssoTeamToInternalUser";
import ssoWebhook from "@/services/sso/models/ssoWebhook";
//EOC
//BOC:[hrdc]
import hrdcApplication from "@/services/hrdc/models/application";
import hrdcEmailClient from "@/services/hrdc/models/emailClient";
import hrdcRevertForm from "@/services/hrdc/models/revertForm";
import hrdcManualInvoice from "@/services/hrdc/models/manualInvoice";
import hrdcClaimSubmit from "@/services/hrdc/models/claimSubmit";
import hrdcClaimApproved from "@/services/hrdc/models/claimApproved";
import hrdcPaymentReceived from "@/services/hrdc/models/paymentReceived";
import hrdcCreditNoteGenerate from "@/services/hrdc/models/creditNoteGenerate";
import hrdcCreditNoteRequested from "@/services/hrdc/models/creditNoteRequested";
import hrdcCreditNoteDocumented from "@/services/hrdc/models/creditNoteDocumented";
import hrdcRefundEmailSubmission from "@/services/hrdc/models/refundEmailSubmission";
import hrdcRefundEmailApproval1 from "@/services/hrdc/models/refundEmailApproval1";
import hrdcRefundEmailApproval2 from "@/services/hrdc/models/refundEmailApproval2";
import hrdcRefundEmailArToAp from "@/services/hrdc/models/refundEmailArToAp";
import hrdcRefundEmailApToCimb from "@/services/hrdc/models/refundApToCimb";
import hrdcRefundFailed from "@/services/hrdc/models/refundFailed";
import hrdcFinanceRefundToClient from "@/services/hrdc/models/financeRefundToClient";
import hrdcEmailPaymentAdviceToClient from "@/services/hrdc/models/emailPaymentAdviceToClient";
import hrdcEnrollmentDetail from "@/services/hrdc/models/enrollmentDetail";
import hrdcGrantNoSubmit from "@/services/hrdc/models/grantNoSubmit";
import hrdcApplicationSubmissionDate from "@/services/hrdc/models/submissionDate";
import HrdcReportAnalysis from "@/services/hrdc/models/reportAnalysis";
import hrdcGeneralInfo from "@/services/hrdc/models/generalInfo";
import HrdcSettingsPrice from "@/services/hrdc/models/price";
import HrdcSettingsErrorLog from "@/services/hrdc/models/errorLog";
import HrdcSettingsJob from "@/services/hrdc/models/job";
import HrdcSettingsXero from "@/services/hrdc/models/xero";
import HrdcSettingsAttachment from "@/services/hrdc/models/attachment";
import HrdcSettingsSurvey from "@/services/hrdc/models/survey";
//EOC
//BOC:[hrdc_einvoicing]
import hrdcEinvoicingApplication from "@/services/hrdc_einvoicing/models/application";
import hrdcEinvoicingEmailClient from "@/services/hrdc_einvoicing/models/emailClient";
import hrdcEinvoicingRevertForm from "@/services/hrdc_einvoicing/models/revertForm";
import hrdcEinvoicingManualInvoice from "@/services/hrdc_einvoicing/models/manualInvoice";
import hrdcEinvoicingClaimSubmit from "@/services/hrdc_einvoicing/models/claimSubmit";
import hrdcEinvoicingClaimApproved from "@/services/hrdc_einvoicing/models/claimApproved";
import hrdcEinvoicingPaymentReceived from "@/services/hrdc_einvoicing/models/paymentReceived";
import hrdcEinvoicingCreditNoteGenerate from "@/services/hrdc_einvoicing/models/creditNoteGenerate";
import hrdcEinvoicingCreditNoteRequested from "@/services/hrdc_einvoicing/models/creditNoteRequested";
import hrdcEinvoicingCreditNoteDocumented from "@/services/hrdc_einvoicing/models/creditNoteDocumented";
import hrdcEinvoicingRefundEmailSubmission from "@/services/hrdc_einvoicing/models/refundEmailSubmission";
import hrdcEinvoicingRefundEmailApproval1 from "@/services/hrdc_einvoicing/models/refundEmailApproval1";
import hrdcEinvoicingRefundEmailApproval2 from "@/services/hrdc_einvoicing/models/refundEmailApproval2";
import hrdcEinvoicingRefundEmailArToAp from "@/services/hrdc_einvoicing/models/refundEmailArToAp";
import hrdcEinvoicingRefundEmailApToCimb from "@/services/hrdc_einvoicing/models/refundApToCimb";
import hrdcEinvoicingRefundFailed from "@/services/hrdc_einvoicing/models/refundFailed";
import hrdcEinvoicingFinanceRefundToClient from "@/services/hrdc_einvoicing/models/financeRefundToClient";
import hrdcEinvoicingEmailPaymentAdviceToClient from "@/services/hrdc_einvoicing/models/emailPaymentAdviceToClient";
import hrdcEinvoicingEnrollmentDetail from "@/services/hrdc_einvoicing/models/enrollmentDetail";
import hrdcEinvoicingGrantNoSubmit from "@/services/hrdc_einvoicing/models/grantNoSubmit";
import hrdcEinvoicingApplicationSubmissionDate from "@/services/hrdc_einvoicing/models/submissionDate";
import HrdcEinvoicingReportAnalysis from "@/services/hrdc_einvoicing/models/reportAnalysis";
import hrdcEinvoicingGeneralInfo from "@/services/hrdc_einvoicing/models/generalInfo";
import HrdcEinvoicingSettingsPrice from "@/services/hrdc_einvoicing/models/price";
import HrdcEinvoicingSettingsErrorLog from "@/services/hrdc_einvoicing/models/errorLog";
import HrdcEinvoicingSettingsEvents from "@/services/hrdc_einvoicing/models/event";
import HrdcEinvoicingSettingsXero from "@/services/hrdc_einvoicing/models/xero";
import HrdcEinvoicingSettingsAttachment from "@/services/hrdc_einvoicing/models/attachment";
//EOC
//BOC:[hrdc_tax_summit]
import hrdcTaxSummitApplication from "@/services/hrdc_tax_summit/models/application";
import hrdcTaxSummitEmailClient from "@/services/hrdc_tax_summit/models/emailClient";
import hrdcTaxSummitRevertForm from "@/services/hrdc_tax_summit/models/revertForm";
import hrdcTaxSummitManualInvoice from "@/services/hrdc_tax_summit/models/manualInvoice";
import hrdcTaxSummitClaimSubmit from "@/services/hrdc_tax_summit/models/claimSubmit";
import hrdcTaxSummitClaimApproved from "@/services/hrdc_tax_summit/models/claimApproved";
import hrdcTaxSummitPaymentReceived from "@/services/hrdc_tax_summit/models/paymentReceived";
import hrdcTaxSummitCreditNoteGenerate from "@/services/hrdc_tax_summit/models/creditNoteGenerate";
import hrdcTaxSummitCreditNoteRequested from "@/services/hrdc_tax_summit/models/creditNoteRequested";
import hrdcTaxSummitCreditNoteDocumented from "@/services/hrdc_tax_summit/models/creditNoteDocumented";
import hrdcTaxSummitRefundEmailSubmission from "@/services/hrdc_tax_summit/models/refundEmailSubmission";
import hrdcTaxSummitRefundEmailApproval1 from "@/services/hrdc_tax_summit/models/refundEmailApproval1";
import hrdcTaxSummitRefundEmailApproval2 from "@/services/hrdc_tax_summit/models/refundEmailApproval2";
import hrdcTaxSummitRefundEmailArToAp from "@/services/hrdc_tax_summit/models/refundEmailArToAp";
import hrdcTaxSummitRefundEmailApToCimb from "@/services/hrdc_tax_summit/models/refundApToCimb";
import hrdcTaxSummitRefundFailed from "@/services/hrdc_tax_summit/models/refundFailed";
import hrdcTaxSummitFinanceRefundToClient from "@/services/hrdc_tax_summit/models/financeRefundToClient";
import hrdcTaxSummitEmailPaymentAdviceToClient from "@/services/hrdc_tax_summit/models/emailPaymentAdviceToClient";
import hrdcTaxSummitEnrollmentDetail from "@/services/hrdc_tax_summit/models/enrollmentDetail";
import hrdcTaxSummitGrantNoSubmit from "@/services/hrdc_tax_summit/models/grantNoSubmit";
import hrdcTaxSummitApplicationSubmissionDate from "@/services/hrdc_tax_summit/models/submissionDate";
import HrdcTaxSummitReportAnalysis from "@/services/hrdc_tax_summit/models/reportAnalysis";
import hrdcTaxSummitGeneralInfo from "@/services/hrdc_tax_summit/models/generalInfo";
import HrdcTaxSummitSettingsPrice from "@/services/hrdc_tax_summit/models/price";
import HrdcTaxSummitSettingsErrorLog from "@/services/hrdc_tax_summit/models/errorLog";
import HrdcTaxSummitSettingsEvents from "@/services/hrdc_tax_summit/models/event";
import HrdcTaxSummitSettingsXero from "@/services/hrdc_tax_summit/models/xero";
import HrdcTaxSummitSettingsAttachment from "@/services/hrdc_tax_summit/models/attachment";
//EOC
//BOC:[hrdc_tpdiy]
import hrdcTpdiyApplication from "@/services/hrdc_tpdiy/models/application";
import hrdcTpdiyEmailClient from "@/services/hrdc_tpdiy/models/emailClient";
import hrdcTpdiyRevertForm from "@/services/hrdc_tpdiy/models/revertForm";
import hrdcTpdiyManualInvoice from "@/services/hrdc_tpdiy/models/manualInvoice";
import hrdcTpdiyClaimSubmit from "@/services/hrdc_tpdiy/models/claimSubmit";
import hrdcTpdiyClaimApproved from "@/services/hrdc_tpdiy/models/claimApproved";
import hrdcTpdiyPaymentReceived from "@/services/hrdc_tpdiy/models/paymentReceived";
import hrdcTpdiyCreditNoteGenerate from "@/services/hrdc_tpdiy/models/creditNoteGenerate";
import hrdcTpdiyCreditNoteRequested from "@/services/hrdc_tpdiy/models/creditNoteRequested";
import hrdcTpdiyCreditNoteDocumented from "@/services/hrdc_tpdiy/models/creditNoteDocumented";
import hrdcTpdiyRefundEmailSubmission from "@/services/hrdc_tpdiy/models/refundEmailSubmission";
import hrdcTpdiyRefundEmailApproval1 from "@/services/hrdc_tpdiy/models/refundEmailApproval1";
import hrdcTpdiyRefundEmailApproval2 from "@/services/hrdc_tpdiy/models/refundEmailApproval2";
import hrdcTpdiyRefundEmailArToAp from "@/services/hrdc_tpdiy/models/refundEmailArToAp";
import hrdcTpdiyRefundEmailApToCimb from "@/services/hrdc_tpdiy/models/refundApToCimb";
import hrdcTpdiyRefundFailed from "@/services/hrdc_tpdiy/models/refundFailed";
import hrdcTpdiyFinanceRefundToClient from "@/services/hrdc_tpdiy/models/financeRefundToClient";
import hrdcTpdiyEmailPaymentAdviceToClient from "@/services/hrdc_tpdiy/models/emailPaymentAdviceToClient";
import hrdcTpdiyEnrollmentDetail from "@/services/hrdc_tpdiy/models/enrollmentDetail";
import hrdcTpdiyGrantNoSubmit from "@/services/hrdc_tpdiy/models/grantNoSubmit";
import hrdcTpdiyApplicationSubmissionDate from "@/services/hrdc_tpdiy/models/submissionDate";
import HrdcTpdiyReportAnalysis from "@/services/hrdc_tpdiy/models/reportAnalysis";
import hrdcTpdiyGeneralInfo from "@/services/hrdc_tpdiy/models/generalInfo";
import HrdcTpdiySettingsPrice from "@/services/hrdc_tpdiy/models/price";
import HrdcTpdiySettingsErrorLog from "@/services/hrdc_tpdiy/models/errorLog";
import HrdcTpdiySettingsEvents from "@/services/hrdc_tpdiy/models/event";
import HrdcTpdiySettingsXero from "@/services/hrdc_tpdiy/models/xero";
import HrdcTpdiySettingsAttachment from "@/services/hrdc_tpdiy/models/attachment";
//EOC

//EOC:[development]
import demoCustomer from "@/services/developer/models/customer";
import demoProduct from "@/services/developer/models/product";
import demoCategory from "@/services/developer/models/category";
import demoOrder from "@/services/developer/models/order";
import demoOrderToDemoProduct from "@/services/developer/models/demoOrderToDemoProduct";
//EOC

export default {
  //BOC:[crm]
  crmService,
  crmServicePermission,
  crmServicePermissionToInternalRole,
  //EOC
  //BOC:[log]
  logRequest,
  //EOC
  //BOC:[sso]
  internalCompany,
  internalDepartment,
  internalRole,
  internalUser,
  internalRoleToInternalUser,
  ssoBusiness,
  ssoMicrosoftAccount,
  ssoTeam,
  ssoTeamToInternalUser,
  ssoWebhook,
  //EOC
  //BOC:[hrdc]
  hrdcApplication,
  hrdcEmailClient,
  hrdcRevertForm,
  hrdcManualInvoice,
  hrdcClaimSubmit,
  hrdcClaimApproved,
  hrdcPaymentReceived,
  hrdcCreditNoteGenerate,
  hrdcCreditNoteRequested,
  hrdcCreditNoteDocumented,
  hrdcRefundEmailSubmission,
  hrdcRefundEmailApproval1,
  hrdcRefundEmailApproval2,
  hrdcRefundEmailArToAp,
  hrdcRefundEmailApToCimb,
  hrdcRefundFailed,
  hrdcFinanceRefundToClient,
  hrdcEmailPaymentAdviceToClient,
  hrdcEnrollmentDetail,
  hrdcGrantNoSubmit,
  hrdcApplicationSubmissionDate,
  HrdcReportAnalysis,
  hrdcGeneralInfo,
  HrdcSettingsPrice,
  HrdcSettingsErrorLog,
  HrdcSettingsJob,
  HrdcSettingsXero,
  HrdcSettingsAttachment,
  HrdcSettingsSurvey,
  //EOC
  //BOC:[hrdc_einvoicing]
  hrdcEinvoicingApplication,
  hrdcEinvoicingEmailClient,
  hrdcEinvoicingRevertForm,
  hrdcEinvoicingManualInvoice,
  hrdcEinvoicingClaimSubmit,
  hrdcEinvoicingClaimApproved,
  hrdcEinvoicingPaymentReceived,
  hrdcEinvoicingCreditNoteGenerate,
  hrdcEinvoicingCreditNoteRequested,
  hrdcEinvoicingCreditNoteDocumented,
  hrdcEinvoicingRefundEmailSubmission,
  hrdcEinvoicingRefundEmailApproval1,
  hrdcEinvoicingRefundEmailApproval2,
  hrdcEinvoicingRefundEmailArToAp,
  hrdcEinvoicingRefundEmailApToCimb,
  hrdcEinvoicingRefundFailed,
  hrdcEinvoicingFinanceRefundToClient,
  hrdcEinvoicingEmailPaymentAdviceToClient,
  hrdcEinvoicingEnrollmentDetail,
  hrdcEinvoicingGrantNoSubmit,
  hrdcEinvoicingApplicationSubmissionDate,
  HrdcEinvoicingReportAnalysis,
  hrdcEinvoicingGeneralInfo,
  HrdcEinvoicingSettingsPrice,
  HrdcEinvoicingSettingsErrorLog,
  HrdcEinvoicingSettingsEvents,
  HrdcEinvoicingSettingsXero,
  HrdcEinvoicingSettingsAttachment,
  //EOC
  //BOC:[hrdc_tax_summit]
  hrdcTaxSummitApplication,
  hrdcTaxSummitEmailClient,
  hrdcTaxSummitRevertForm,
  hrdcTaxSummitManualInvoice,
  hrdcTaxSummitClaimSubmit,
  hrdcTaxSummitClaimApproved,
  hrdcTaxSummitPaymentReceived,
  hrdcTaxSummitCreditNoteGenerate,
  hrdcTaxSummitCreditNoteRequested,
  hrdcTaxSummitCreditNoteDocumented,
  hrdcTaxSummitRefundEmailSubmission,
  hrdcTaxSummitRefundEmailApproval1,
  hrdcTaxSummitRefundEmailApproval2,
  hrdcTaxSummitRefundEmailArToAp,
  hrdcTaxSummitRefundEmailApToCimb,
  hrdcTaxSummitRefundFailed,
  hrdcTaxSummitFinanceRefundToClient,
  hrdcTaxSummitEmailPaymentAdviceToClient,
  hrdcTaxSummitEnrollmentDetail,
  hrdcTaxSummitGrantNoSubmit,
  hrdcTaxSummitApplicationSubmissionDate,
  HrdcTaxSummitReportAnalysis,
  hrdcTaxSummitGeneralInfo,
  HrdcTaxSummitSettingsPrice,
  HrdcTaxSummitSettingsErrorLog,
  HrdcTaxSummitSettingsEvents,
  HrdcTaxSummitSettingsXero,
  HrdcTaxSummitSettingsAttachment,
  //EOC
  //BOC:[hrdc_tpdiy]
  hrdcTpdiyApplication,
  hrdcTpdiyEmailClient,
  hrdcTpdiyRevertForm,
  hrdcTpdiyManualInvoice,
  hrdcTpdiyClaimSubmit,
  hrdcTpdiyClaimApproved,
  hrdcTpdiyPaymentReceived,
  hrdcTpdiyCreditNoteGenerate,
  hrdcTpdiyCreditNoteRequested,
  hrdcTpdiyCreditNoteDocumented,
  hrdcTpdiyRefundEmailSubmission,
  hrdcTpdiyRefundEmailApproval1,
  hrdcTpdiyRefundEmailApproval2,
  hrdcTpdiyRefundEmailArToAp,
  hrdcTpdiyRefundEmailApToCimb,
  hrdcTpdiyRefundFailed,
  hrdcTpdiyFinanceRefundToClient,
  hrdcTpdiyEmailPaymentAdviceToClient,
  hrdcTpdiyEnrollmentDetail,
  hrdcTpdiyGrantNoSubmit,
  hrdcTpdiyApplicationSubmissionDate,
  HrdcTpdiyReportAnalysis,
  hrdcTpdiyGeneralInfo,
  HrdcTpdiySettingsPrice,
  HrdcTpdiySettingsErrorLog,
  HrdcTpdiySettingsEvents,
  HrdcTpdiySettingsXero,
  HrdcTpdiySettingsAttachment,
  //EOC
  //EOC:[development]
  demoCustomer,
  demoProduct,
  demoCategory,
  demoOrder,
  demoOrderToDemoProduct,
  //EOC
};
