<template>
  <div>
    <v-menu
      open-on-hover
      bottom
      offset-y
      :close-on-content-click="false"
      :close-on-click="false"
      rounded="lg"
      min-width="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn text dark elevation="0" v-bind="attrs" v-on="on" class="px-1">
          <span class="auth-box1"> {{ auth.name }} ▼ </span>
        </v-btn>
      </template>

      <v-list>
        <v-list-group prepend-icon="mdi-account-details" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>My Roles</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="(role, index) in auth.roles" :key="index" dense>
            <v-list-item-content>
              <v-list-item-title>
                <v-chip :color="HRDC_BLUE_THEME_COLOR" outlined>
                  <v-icon left> mdi-account-outline </v-icon>
                  {{ role.name }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <v-divider color="grey"></v-divider>
        <div class="d-flex flex-column">
          <img
            :src="FEEDBACK_MICROSOFT_QR_URL"
            style="align-self: center; width: 250px;"
          />
          <a :href="FEEDBACK_MICROSOFT_URL" target="_blank" class="text-center"
            >Click me for feedback</a
          >
        </div> -->
        <v-divider color="grey"></v-divider>

        <v-list-item @click="toggleLogoutDialogChild(true)" link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppHeaderProfile",
  emits: ["toggleLogoutDialogChild"],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  methods: {
    toggleLogoutDialogChild(bool) {
      this.$emit("toggleLogoutDialogChild", bool);
    },
  },
};
</script>
<style type="text/css">
.auth-box {
  animation: textBlink 10s infinite alternate !important;
}

@keyframes textBlink {
  0%,
  10%,
  20%,
  30%,
  40%,
  50%,
  60%,
  70%,
  80%,
  90%,
  100% {
    color: #fff;
    text-shadow: 0 0 10px #ef5226, 0 0 20px #ef5226, 0 0 30px #ef5226,
      0 0 50px #ef5226, 0 0 120px #ef5226;
  }

  5%,
  15%,
  25%,
  35%,
  45%,
  55%,
  65%,
  75%,
  85%,
  95% {
    text-shadow: none;
  }
}
</style>
