export default {
  key: "HrdcSettingsSurvey",
  serviceKey: "hrdc",
  name: {
    singular: "Survey",
    plural: "Surveys",
  },
  parent_route: "HrdcSettings",
  add: {
    name: "Survey",
  },
  view: {
    name: "Survey",
  },
};
