import LayoutNested from "@/components/layouts/LayoutNested.vue";

export default {
  path: `/service/:serviceKey`,
  component: LayoutNested,
  children: [
    {
      path: "application/view_tax_summit",
      name: "ViewHrdcTaxSummitApplication",
      component: () =>
        import("@/services/hrdc_tax_summit/views/application/View.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/edit_tax_summit",
      name: "EditHrdcTaxSummitApplication",
      component: () =>
        import("@/services/hrdc_tax_summit/views/application/Edit.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/add_tax_summit",
      name: "AddHrdcTaxSummitApplication",
      component: () =>
        import("@/services/hrdc_tax_summit/views/application/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application_tax_summit",
      name: "HrdcTaxSummitApplication",
      component: () =>
        import("@/services/hrdc_tax_summit/views/application/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcTaxSummitApplication",
        },
      },
    },
    {
      path: "application/email/client_tax_summit",
      name: "HrdcTaxSummitSendEmailToClient",
      component: () =>
        import("@/services/hrdc_tax_summit/views/email_client/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitEmailClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/revert_form_tax_summit",
      name: "HrdcTaxSummitClientRevertForm",
      component: () =>
        import("@/services/hrdc_tax_summit/views/revert/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRevertForm",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/manual_invoice_tax_summit",
      name: "HrdcTaxSummitManualInvoiceToHrdc",
      component: () =>
        import("@/services/hrdc_tax_summit/views/manual_invoice/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitManualInvoice",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_submit_tax_summit",
      name: "HrdcTaxSummitClaimSubmitToHrdc",
      component: () =>
        import("@/services/hrdc_tax_summit/views/claim_submit/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitClaimSubmit",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_approved_tax_summit",
      name: "HrdcTaxSummitClaimApprovedFromHrdc",
      component: () =>
        import("@/services/hrdc_tax_summit/views/claim_approved/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitClaimApproved",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/payment_received_tax_summit",
      name: "HrdcTaxSummitPaymentReceivedFromHrdc",
      component: () =>
        import("@/services/hrdc_tax_summit/views/payment_received/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitPaymentReceived",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_submission_tax_summit",
      name: "HrdcTaxSummitRefundEmailSubmission",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/refund_email_submission/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRefundEmailSubmission",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_1_tax_summit",
      name: "HrdcTaxSummitRefundEmailApproval1",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/refund_email_approval_1/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRefundEmailApproval1",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_2_tax_summit",
      name: "HrdcTaxSummitRefundEmailApproval2",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/refund_email_approval_2/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRefundEmailApproval2",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_ar_to_ap_tax_summit",
      name: "HrdcTaxSummitRefundEmailArToAp",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/refund_email_ar_to_ap/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRefundEmailArToAp",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "refund_ap_to_cimb_tax_summit",
      name: "HrdcTaxSummitRefundEmailApToCimb",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/finance_ap_submit_refund_to_cimb/Index.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitRefundEmailApToCimb",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/finance_refund_to_client_tax_summit",
      name: "HrdcTaxSummitFinanceRefundToClient",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/finance_refund_to_client/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitFinanceRefundToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/email_payment_advice_to_client_tax_summit",
      name: "HrdcTaxSummitEmailPaymentAdviceToClient",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/email_payment_advice_to_client/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitEmailPaymentAdviceToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "manual_invoice/bulk_upload_tax_summit",
      name: "HrdcTaxSummitManualInvoiceToHrdcBulk",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/manual_invoice/ManualInvoiceToHrdc.vue"
        ),
      props: {
        params: {
          modelKey: "hrdcTaxSummitManualInvoice",
        },
      },
    },
    {
      path: "submission_date_tax_summit",
      name: "HrdcTaxSummitApplicationSubmissionDate",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/application/SubmissionDate.vue"
        ),
      props: {
        params: {
          modelKey: "hrdcTaxSummitApplicationSubmissionDate",
        },
      },
    },
    {
      path: "report_analysis_tax_summit",
      name: "HrdcTaxSummitReportAnalysis",
      component: () =>
        import("@/services/hrdc_tax_summit/views/report/ReportAnalysis.vue"),
      props: {
        params: {
          modelKey: "HrdcTaxSummitReportAnalysis",
        },
      },
    },
    {
      path: "general_info_tax_summit",
      name: "HrdcTaxSummitAddGeneralInfo",
      component: () =>
        import("@/services/hrdc_tax_summit/views/general_info/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTaxSummitGeneralInfo",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "settings_tax_summit",
      name: "HrdcTaxSummitSettings",
      component: () =>
        import("@/services/hrdc_tax_summit/views/settings/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsPrice",
        },
      },
    },
    {
      path: "settings/price_tax_summit",
      name: "HrdcTaxSummitSettingsPrice",
      component: () =>
        import("@/services/hrdc_tax_summit/views/settings/price/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsPrice",
        },
      },
    },
    {
      path: "settings/error_log_tax_summit",
      name: "HrdcTaxSummitSettingsErrorLog",
      component: () =>
        import("@/services/hrdc_tax_summit/views/settings/error_log/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsErrorLog",
        },
      },
    },
    {
      path: "settings/events_tax_summit",
      name: "HrdcTaxSummitSettingsEvents",
      component: () =>
        import("@/services/hrdc_tax_summit/views/settings/event/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsEvents",
        },
      },
    },
    {
      path: "settings/xero_tax_summit",
      name: "HrdcTaxSummitSettingsXeroToken",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/settings/xero_token/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsXero",
        },
      },
    },
    {
      path: "settings/attachment_tax_summit",
      name: "HrdcTaxSummitSettingsAttachment",
      component: () =>
        import(
          "@/services/hrdc_tax_summit/views/settings/attachment/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcTaxSummitSettingsAttachment",
        },
      },
    },
    {
      path: "callback_tax_summit",
      component: () =>
        import("@/services/hrdc_tax_summit/views/callback/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcTaxSummitApplication",
        },
      },
    },
    {
      path: "callback_xero_tax_summit",
      component: () =>
        import("@/services/hrdc_tax_summit/views/callback/xero/Index.vue"),
    },
  ],
};
