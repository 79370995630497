export default {
  key: "HrdcEinvoicingSettingsAttachment",
  serviceKey: "hrdc_einvoicing",
  name: {
    singular: "Attachment",
    plural: "Attachments",
  },
  parent_route: "HrdcEinvoicingSettings",
  add: {
    name: "Attachment",
  },
  view: {
    name: "Attachment",
  },
};
