export default {
  key: "HrdcTaxSummitSettingsAttachment",
  serviceKey: "hrdc_tax_summit",
  name: {
    singular: "Attachment",
    plural: "Attachments",
  },
  parent_route: "HrdcTaxSummitSettings",
  add: {
    name: "Attachment",
  },
  view: {
    name: "Attachment",
  },
};
