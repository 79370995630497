import LayoutNested from "@/components/layouts/LayoutNested.vue";

export default {
  path: `/service/:serviceKey`,
  component: LayoutNested,
  children: [
    {
      path: "application/view_einvoicing",
      name: "ViewHrdcEinvoicingApplication",
      component: () =>
        import("@/services/hrdc_einvoicing/views/application/View.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/edit_einvoicing",
      name: "EditHrdcEinvoicingApplication",
      component: () =>
        import("@/services/hrdc_einvoicing/views/application/Edit.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/add_einvoicing",
      name: "AddHrdcEinvoicingApplication",
      component: () =>
        import("@/services/hrdc_einvoicing/views/application/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application_einvoicing",
      name: "HrdcEinvoicingApplication",
      component: () =>
        import("@/services/hrdc_einvoicing/views/application/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcEinvoicingApplication",
        },
      },
    },
    {
      path: "application/email/client_einvoicing",
      name: "HrdcEinvoicingSendEmailToClient",
      component: () =>
        import("@/services/hrdc_einvoicing/views/email_client/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingEmailClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/revert_form_einvoicing",
      name: "HrdcEinvoicingClientRevertForm",
      component: () =>
        import("@/services/hrdc_einvoicing/views/revert/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRevertForm",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/manual_invoice_einvoicing",
      name: "HrdcEinvoicingManualInvoiceToHrdc",
      component: () =>
        import("@/services/hrdc_einvoicing/views/manual_invoice/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingManualInvoice",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_submit_einvoicing",
      name: "HrdcEinvoicingClaimSubmitToHrdc",
      component: () =>
        import("@/services/hrdc_einvoicing/views/claim_submit/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingClaimSubmit",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_approved_einvoicing",
      name: "HrdcEinvoicingClaimApprovedFromHrdc",
      component: () =>
        import("@/services/hrdc_einvoicing/views/claim_approved/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingClaimApproved",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/payment_received_einvoicing",
      name: "HrdcEinvoicingPaymentReceivedFromHrdc",
      component: () =>
        import("@/services/hrdc_einvoicing/views/payment_received/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingPaymentReceived",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_submission_einvoicing",
      name: "HrdcEinvoicingRefundEmailSubmission",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/refund_email_submission/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRefundEmailSubmission",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_1_einvoicing",
      name: "HrdcEinvoicingRefundEmailApproval1",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/refund_email_approval_1/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRefundEmailApproval1",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_2_einvoicing",
      name: "HrdcEinvoicingRefundEmailApproval2",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/refund_email_approval_2/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRefundEmailApproval2",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_ar_to_ap_einvoicing",
      name: "HrdcEinvoicingRefundEmailArToAp",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/refund_email_ar_to_ap/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRefundEmailArToAp",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "refund_ap_to_cimb_einvoicing",
      name: "HrdcEinvoicingRefundEmailApToCimb",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/finance_ap_submit_refund_to_cimb/Index.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingRefundEmailApToCimb",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/finance_refund_to_client_einvoicing",
      name: "HrdcEinvoicingFinanceRefundToClient",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/finance_refund_to_client/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingFinanceRefundToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/email_payment_advice_to_client_einvoicing",
      name: "HrdcEinvoicingEmailPaymentAdviceToClient",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/email_payment_advice_to_client/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingEmailPaymentAdviceToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "manual_invoice/bulk_upload_einvoicing",
      name: "HrdcEinvoicingManualInvoiceToHrdcBulk",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/manual_invoice/ManualInvoiceToHrdc.vue"
        ),
      props: {
        params: {
          modelKey: "hrdcEinvoicingManualInvoice",
        },
      },
    },
    {
      path: "submission_date_einvoicing",
      name: "HrdcEinvoicingApplicationSubmissionDate",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/application/SubmissionDate.vue"
        ),
      props: {
        params: {
          modelKey: "hrdcEinvoicingApplicationSubmissionDate",
        },
      },
    },
    {
      path: "report_analysis_einvoicing",
      name: "HrdcEinvoicingReportAnalysis",
      component: () =>
        import("@/services/hrdc_einvoicing/views/report/ReportAnalysis.vue"),
      props: {
        params: {
          modelKey: "HrdcEinvoicingReportAnalysis",
        },
      },
    },
    {
      path: "general_info_einvoicing",
      name: "HrdcEinvoicingAddGeneralInfo",
      component: () =>
        import("@/services/hrdc_einvoicing/views/general_info/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEinvoicingGeneralInfo",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "settings_einvoicing",
      name: "HrdcEinvoicingSettings",
      component: () =>
        import("@/services/hrdc_einvoicing/views/settings/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsPrice",
        },
      },
    },
    {
      path: "settings/price_einvoicing",
      name: "HrdcEinvoicingSettingsPrice",
      component: () =>
        import("@/services/hrdc_einvoicing/views/settings/price/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsPrice",
        },
      },
    },
    {
      path: "settings/error_log_einvoicing",
      name: "HrdcEinvoicingSettingsErrorLog",
      component: () =>
        import("@/services/hrdc_einvoicing/views/settings/error_log/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsErrorLog",
        },
      },
    },
    {
      path: "settings/events_einvoicing",
      name: "HrdcEinvoicingSettingsEvents",
      component: () =>
        import("@/services/hrdc_einvoicing/views/settings/event/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsEvents",
        },
      },
    },
    {
      path: "settings/xero_token_einvoicing",
      name: "HrdcEinvoicingSettingsXeroToken",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/settings/xero_token/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsXero",
        },
      },
    },
    {
      path: "settings/attachment_einvoicing",
      name: "HrdcEinvoicingSettingsAttachment",
      component: () =>
        import(
          "@/services/hrdc_einvoicing/views/settings/attachment/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcEinvoicingSettingsAttachment",
        },
      },
    },
    {
      path: "callback_einvoicing",
      component: () =>
        import("@/services/hrdc_einvoicing/views/callback/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcEinvoicingApplication",
        },
      },
    },
    {
      path: "callback_xero_einvoicing",
      component: () =>
        import("@/services/hrdc_einvoicing/views/callback/xero/Index.vue"),
    },
  ],
};
